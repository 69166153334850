<div (click)="back()"
    class="tw-fixed tw-left-4 tw-top-6 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-3 tw-z-20">
    <cs-icon class="tw-text-white tw-h-6 tw-w-6" [iconName]="'arrow_back'"></cs-icon>
</div>

<loading class="tw-flex tw-justify-center tw-h-full" *ngIf="isLoading"></loading>

@if (!isLoading) {
<section class="tw-text-200 tw-mx-4">
    <p>
        <span>Personalised For:</span>&nbsp;
        <span class="tw-font-bold tw-capitalize">{{user.get('PatientName')?.split(' ')[0]}}, {{user.get('Age')}}
            years</span>
    </p>
    <p *ngIf="regimen?.displayConcern?.name">
        <span>Treatment Goal:</span>&nbsp;
        <span class="tw-font-bold">{{regimen?.displayConcern.name}}</span>
    </p>
    <p *ngIf="regimen?.displayConcern?.otherGoals" class="tw-text-gray-400 tw-italic">
        <span>Other Goals:</span>&nbsp;
        <span>{{regimen?.displayConcern?.otherGoals}}</span>
    </p>
</section>

<!-- Divider -->
<div class="tw-bg-black tw-opacity-10 tw-h-[1px] tw-mx-4 tw-my-2"></div>

<section *ngIf="allocatedDoctor" (click)="doctorCardClick()" class="tw-flex tw-items-end tw-gap-2 tw-mx-4 tw-mb-6">
    <div class="tw-h-10 tw-w-10 tw-rounded-full tw-bg-gray-300 tw-overflow-hidden">
        <img class="tw-max-w-full tw-max-h-full" [src]="allocatedDoctor.get('doctorDisplayImage')">
    </div>
    <div class="tw-flex tw-flex-col tw-text-100 tw-gap-0.5">
        <h3 class="tw-text-orange-500 tw-font-bold tw-uppercase">Your Skin Specialist</h3>
        <p>{{allocatedDoctor.get('DoctorDisplayName')}}</p>
    </div>
</section>

<!-- Product card -->
@for (product of products; track $index) {
<section class="tw-bg-white tw-rounded-4 tw-border tw-border-gray-200 tw-p-4 tw-mx-4 tw-mb-4">
    <!-- header -->
    <div class="tw-flex tw-items-center tw-text-200 tw-mb-4">
        <h2 class="tw-uppercase tw-font-bold tw-mr-4">
            <span>{{$index + 1}}.</span>&nbsp;<span>{{product.product?.purpose}}</span>
        </h2>
        <div *ngIf="product?.use.includes('morning')" class="tw-flex tw-items-center tw-gap-1 tw-mr-2">
            <cs-icon class="tw-h-4 tw-w-4" [iconName]="'light_full'"></cs-icon>
            <span>Morning</span>
        </div>
        <div *ngIf="product?.use.includes('night')" class="tw-flex tw-items-center tw-gap-1">
            <cs-icon class="tw-h-4 tw-w-4" [iconName]="'night_full'"></cs-icon>
            <span>Night</span>
        </div>
    </div>
    <!-- Images -->
    <div class="tw-mb-6 tw-overflow-hidden tw-flex tw-items-center tw-gap-3 -tw-mx-4">
        <div *ngIf="product.images.length === 1"
            class="tw-h-[220px] tw-w-fit tw-overflow-hidden tw-rounded-4 tw-bg-gray-100 tw-relative tw-mx-4">
            <div class="tw-absolute tw-top-2 tw-left-2 tw-bg-white tw-rounded-4 tw-px-1.5 tw-z-10">
                <p class="tw-text-100">{{product.product?.quantity}} {{product.product?.quantityUnit}}</p>
            </div>
            <img [src]="product.images[0].url" class="tw-h-full tw-w-fit" (click)="openSlideShow(product)">
        </div>
        <swiper *ngIf="product.images.length != 1" [config]="swiperConfig">
            @for (object of product.images; track $index; let first = $first; let last = $last;) {
            <div *swiperSlide>
                <div class="tw-h-fit tw-overflow-hidden tw-rounded-4 tw-bg-gray-100 tw-relative tw-mx-2"
                    [ngClass]="{'tw-ml-4': first, 'tw-mr-4': last}">
                    <div *ngIf="$index === 0"
                        class="tw-absolute tw-top-2 tw-left-2 tw-bg-white tw-rounded-4 tw-px-1.5 tw-z-10">
                        <p class="tw-text-100">{{product.product?.quantity}} {{product.product?.quantityUnit}}</p>
                    </div>
                    <img [src]="object.url" class="tw-h-fit tw-w-auto" (click)="openSlideShow(product)">
                </div>
            </div>
            }
        </swiper>
    </div>
    <!-- Content -->
    <div [ngClass]="{'tw-mb-6': product?.video}">
        <h1 class="tw-text-500 tw-font-bold tw-mb-2">{{product.product?.title}}</h1>
        <p class="tw-text-200 tw-text-gray-500 tw-mb-4">{{product.product?.description}}</p>
        <div *ngIf="product?.product?.productHighlights?.length && product?.product?.productHighlights[0]?.image && isShowHighlight"
            class="tw-flex tw-gap-2 tw-mb-4 tw-text-200">
            @for (highlight of product?.product?.productHighlights; track $index) {
            <div class="tw-flex tw-flex-col tw-gap-2">
                <div class="tw-h-10 tw-w-10">
                    <img class="tw-h-full tw-w-full" [src]="highlight?.image">
                </div>
                <p [innerHTML]="highlight?.description"></p>
            </div>
            }
        </div>
        <div *ngIf="product.product?.ingredients && !product.product?.ingredients?.keyActive"
            class="tw-bg-gray-100 tw-rounded-1 tw-p-2">
            <p class="tw-text-200 tw-text-gray-500 tw-italic">Key Ingredients:
                {{product.product?.ingredients}}</p>
        </div>
        <div *ngIf="product.product?.ingredients?.keyActive" class="tw-bg-gray-100 tw-rounded-1 tw-p-2">
            <p class="tw-text-200 tw-text-gray-500 tw-italic">Key Ingredients:
                {{product.product?.ingredients?.keyActive}}</p>
        </div>
    </div>
    <!-- Footer -->
    <div class="tw-flex tw-items-center tw-gap-2 tw-relative shine loop" *ngIf="product?.video"
        (click)="openVideoPlayer(product?.video)">
        <p class="tw-text-teal-600 tw-font-bold tw-text-300">See how to apply</p>
        <cs-icon class="tw-h-7 tw-w-7" [iconName]="'play-icon-teal'"></cs-icon>
    </div>
</section>
}
}