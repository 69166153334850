<cs-icon class="tw-text-white tw-h-6 tw-w-6 tw-absolute tw-top-6 tw-right-6" [iconName]="'close'"
    (click)="close()"></cs-icon>
<section class="tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-6 tw-overflow-hidden">
    <div *ngIf="data.product.images?.length === 1" class="tw-px-6">
        <img class="tw-w-fit tw-h-auto tw-rounded-3" [src]="data.product.images[0].url">
    </div>
    <swiper *ngIf="data.product.images?.length > 1" class="tw-w-full tw-overflow-hidden" [config]="swiperConfig"
        (slideChange)="currentActiveSlide = $event[0].activeIndex">
        @for (object of data.product.images; track $index) {
        <div *swiperSlide class="tw-w-full tw-h-full tw-px-6 tw-box-border">
            <img class="tw-w-auto tw-h-full tw-rounded-3" [src]="object.url">
        </div>
        }
    </swiper>
    <div class="tw-w-auto tw-flex tw-items-center tw-gap-2 tw-mx-auto tw-overflow-auto" id="swiper-pagination"></div>
</section>